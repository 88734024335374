import classNames from "classnames";
import { Layout } from "organisms";
import { Headline, Image, Metadata, Obl } from "atoms";
import Obfuscate from 'react-obfuscate';
import React from "react";
import * as styles from "./index.module.scss";

const StoryPage = () => (<div data-testid="index" className={styles.story}>
    <Metadata
        title="Our Story"
        description="This is the story behind Melalogic."
    />
    <article>
        <Layout>
            <div className="row center-xs middle-xs">
                <div className="col-xs-12 col-sm-5">
                    <Image filename="story-headshot-min.jpg" alt="CEO & Founder, Avery Smith" />
                </div>
                <div className={classNames("col-xs-12 col-sm-5 start-sm", styles.contentCol)}>
                    <Headline>Our Story</Headline>
                    <div className={styles.bloombergVideo}>
                        <iframe title="Bloomberg Interviews Melalogic: Ending Skin Tone Disparity In Dermatology" src="https://bloomberg.com/media-manifest/embed/iframe?id=b6bec309-053e-4be5-84f3-6037e3e4e3b7" allowscriptaccess="always" />
                    </div>
                    <p className={styles.bloombergVideoCaption}>Quicktake, “Ending Skin Tone Disparity In Dermatology.” Bloomberg News. November 21, 2022. News video, 6:36. <Obl to="https://bloomberg.com/news/videos/2022-11-21/ending-skin-tone-disparity-in-dermatology" title="Bloomberg News Interviews Melalogic">https://bloomberg.com/news/videos/2022-11-21/ending-skin-tone-disparity-in-dermatology</Obl></p>
                    <p>Hi 👋🏾, My name is Avery Smith, and I'm a software engineer with just over 23 years of experience. I want every person with questions about black skin issues to get satisfactory answers, quickly, and inexpensively.</p>
                    <p>Having lost a loved one to skin cancer, I know how unfair the healthcare system can be toward black people, and I want to help change it. Thus was born Melalogic, which is a platform that gives black people a single source of skin health information from trusted professionals who look like them.</p>
                    <p>What role do you play in our story? Email me at <Obfuscate
                            email="avery@melalogic.com"
                            headers={{
                                subject: 'getting in touch with you about Melalogic',
                                body: 'Hi Avery, my name is ...',
                            }}
                        /> to get in touch.</p>
                </div>
                <div className="col-sm-1" />
            </div>
        </Layout>
    </article>
</div>)

export default StoryPage
